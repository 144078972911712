import { pathUrl } from "../../data/path-url";
import { getNavigate } from "../../navigate-service.";
import { userType } from "./user.type";

export const userActions = {
  getUserInfo,
  setPermissions,
};

function getUserInfo() {
  let userInfo = JSON.parse(localStorage.getItem("user_info") as string);
  if (userInfo) {
    return (dispatch: any) => {
      dispatch({
        type: userType.SET_USER_INFO,
        payload: userInfo
      });
    };
  } else {
    if (window.location.pathname != pathUrl.LOGIN) {
      const navigate = getNavigate();
      navigate(pathUrl.LOGIN);
    }
    return (dispatch: any) => {
      dispatch({
        type: userType.SET_USER_INFO,
        payload: undefined
      });
    };
  }
}

function setPermissions() {
  let userPermissions = JSON.parse(localStorage.getItem("user_permissions") as string);
  return (dispatch: any) => {
    dispatch({
      type: userType.SET_PERMISSIONS,
      payload: userPermissions || []
    });
  };
}
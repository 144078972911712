import React, { Component } from 'react';
import english from "../langs/en.translations.json";
import vietnam from "../langs/vi.translations.json";
import { LocalizeContextProps, Translate, withLocalize } from "react-localize-redux";
import { DateTimeEnum, LangEnum } from "../constants/enums";
import { Image, Layout, Menu, MenuProps, Spin } from "antd";
import { pageRouter } from "../data/page-router";
import { NavLink, Route, Routes, redirect } from "react-router-dom";
import { bindActionCreators } from "@reduxjs/toolkit";
import { ConnectedProps, connect } from "react-redux";
import Page404 from "./page-404";
import AppHeader from './app-header';
import { IMAGE } from '../assets/images';
import { isAllow, leftMenu } from '../data/left-menu';
import { userActions } from '../store/user/user.actions';
import { pathUrl } from '../data/path-url';
import { getNavigate } from '../navigate-service.';
import { commonActions } from '../store/common/common.actions';
import { Header } from 'antd/es/layout/layout';
import AppMenu from './app-menu';

const { Content } = Layout;

const mapStateToProps = (state: any) => {
  return {
    loadSpin: state.commonReducer.loadSpin,
    userInfo: state.userReducer.userInfo,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({
    getUserInfo: userActions.getUserInfo,
    setPermissions: userActions.setPermissions,
    setFormatDatetime: commonActions.setFormatDatetime,
  }, dispatch);
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux & LocalizeContextProps & {
  loadSpin?: any;
  userInfo?: any;
};

interface IState {
  browserWeb: string,
  // collapsed: boolean,
  lang: string,
  formatDatetime: any
};

class App extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    let query = new URLSearchParams(window.location.search);

    let langDefault = query.get("lang") || JSON.parse(localStorage.getItem("lang") as string) || LangEnum.VI;
    let formatDatetimeDefault = query.get("format_datetime") || JSON.parse(localStorage.getItem("format_datetime") as string) || DateTimeEnum.DD_MM_YYYY_HH_MM_SS_Z;

    localStorage.setItem("lang", JSON.stringify(langDefault));
    localStorage.setItem("format_datetime", JSON.stringify(formatDatetimeDefault));

    this.props.initialize({
      languages: [
        { code: LangEnum.EN, name: "English", },
        { code: LangEnum.VI, name: "Vietnam", },
      ],
      translation: vietnam,
      options: {
        onMissingTranslation: ({ translationId }) => translationId,
        renderToStaticMarkup: false,
        renderInnerHtml: true,
        defaultLanguage: langDefault,
      },
    });
    this.props.addTranslationForLanguage(vietnam, LangEnum.VI);
    this.props.addTranslationForLanguage(english, LangEnum.EN);
    this.state = {
      // collapsed: false,
      lang: langDefault,
      browserWeb: "PC",
      formatDatetime: formatDatetimeDefault
    };

    this.props.setFormatDatetime(formatDatetimeDefault);

  }

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', e => this.handleWindowResize());
    setTimeout(() => {
      let token = JSON.parse(localStorage.getItem("acc_token") as string);
      if (!token) {
        const navigate = getNavigate();
        if (navigate) {
          if (window.location.pathname != pathUrl.LOGIN) {
            navigate(pathUrl.LOGIN.concat(`?continue=${encodeURIComponent(window.location.href)}`));
          } else {
            navigate(pathUrl.LOGIN);
          }
        }
      } else {
        this.props.getUserInfo();
        this.props.setPermissions();
      }
    }, 10);

  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', e => this.handleWindowResize());
  }

  handleWindowResize() {
    let windowSize = this.getWindowSize();
    if (windowSize.innerWidth <= 768) {
      // Moblie
      this.setState({
        browserWeb: "MOBLIE"
      })
    } else {
      // PC, Tablet
      this.setState({
        browserWeb: "PC"
      })
    }
  }

  getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  onChangeLang(value: string) {
    this.setState({
      lang: value
    }, () => {
      localStorage.setItem("lang", JSON.stringify(value));
      this.props.setActiveLanguage(value);
    })
  }

  onChangeDatetime(value: string) {
    this.setState({
      formatDatetime: value
    }, () => {
      localStorage.setItem("format_datetime", JSON.stringify(value));
      this.props.setFormatDatetime(value);
    })
  }

  onShowMenu(userInfo: any, browserWeb: string) {
    let isCheck = !userInfo || browserWeb == "WEB";
    return isCheck;
  }

  render() {
    // collapsed,
    let { browserWeb, lang, formatDatetime } = this.state;
    let { loadSpin, userInfo, getUserInfo } = this.props;

    console.log("userInfo",userInfo);

    return (
      <Spin spinning={loadSpin > 0} size="large">
        <Layout>
          <AppHeader
            browserWeb={browserWeb}
            userInfo={userInfo}
            lang={lang}
            formatDatetime={formatDatetime}
            onChangeDatetime={(newDatetime) => this.onChangeDatetime(newDatetime)}
            onChangeLang={(newLang) => this.onChangeLang(newLang)}
            getUserInfo={() => getUserInfo()}
          />
          {
            userInfo && browserWeb == "PC" && (
              <Header
                className='app-header-menu'
              >
                <AppMenu mode="horizontal" />
              </Header>
            )
          }
          <Content>
            <React.Suspense fallback={<Spin />}>
              <Routes>
                {pageRouter.map((k: any, i: number) => {
                  let Page = k.component;
                  return (
                    <Route
                      key={i}
                      path={k.path}
                      element={<Page />}
                    />
                  )
                }
                )}
                <Route path="*" element={<Page404 />} />
              </Routes>
            </React.Suspense>
          </Content>
        </Layout>
      </Spin>
    );
  }
}


export default connector(withLocalize(App));

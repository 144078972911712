import React from "react";
import { pathUrl } from "./path-url";

// Public
const login = React.lazy(() => import("../containers/login"));
const changePassword = React.lazy(() => import("../containers/change-password"));
// private
const home = React.lazy(() => import("../containers/home"));
const page500 = React.lazy(() => import("../containers/page-500"));
const page400 = React.lazy(() => import("../containers/page-400"));
const user = React.lazy(() => import("../containers/user"));
const userDetail = React.lazy(() => import("../containers/user/detail"));
const role = React.lazy(() => import("../containers/role"));
const roleDetail = React.lazy(() => import("../containers/role/detail"));
const permission = React.lazy(() => import("../containers/permission"));
const permissionDetail = React.lazy(() => import("../containers/permission/detail"));
const rolePermission = React.lazy(() => import("../containers/role-permission"));
const merchant = React.lazy(() => import("../containers/merchant"));
const merchantDetail = React.lazy(() => import("../containers/merchant/detail"));
const store = React.lazy(() => import("../containers/store"));
const storeDetail = React.lazy(() => import("../containers/store/detail"));
const groupDevice = React.lazy(() => import("../containers/group-device"));
const groupDeviceDetail = React.lazy(() => import("../containers/group-device/detail"));
const service = React.lazy(() => import("../containers/service"));
const serviceDetail = React.lazy(() => import("../containers/service/detail"));
const schedule = React.lazy(() => import("../containers/schedule"));
const scheduleDetail = React.lazy(() => import("../containers/schedule/detail"));
const bookingObject = React.lazy(() => import("../containers/booking-object"));
const bookingObjectDetail = React.lazy(() => import("../containers/booking-object/detail"));
const relayDevice = React.lazy(() => import("../containers/relay-device"));
const relayDeviceDetail = React.lazy(() => import("../containers/relay-device/detail"));
const config = React.lazy(() => import("../containers/config"));
const configDetail = React.lazy(() => import("../containers/config/detail"));
const trackingRequest = React.lazy(() => import("../containers/tracking-request"));
const trackingRequestDetail = React.lazy(() => import("../containers/tracking-request/detail"));


export interface IRouter {
  path: string;
  title: string;
  component: any;
}

export const pageRouter: IRouter[] = [
  {
    path: pathUrl.HOME,
    title: "home",
    component: home,
  },
  {
    path: pathUrl.PAGE_500,
    title: "Page 500",
    component: page500,
  },
  {
    path: pathUrl.PAGE_400,
    title: "Page 400",
    component: page400,
  },
  {
    path: pathUrl.LOGIN,
    title: "login",
    component: login,
  },
  {
    path: pathUrl.CHANGE_PASSWORD,
    title: "Change password",
    component: changePassword,
  },
  {
    path: pathUrl.USER.INDEX,
    title: "User",
    component: user,
  },
  {
    path: pathUrl.USER.DETAIL,
    title: "User detail",
    component: userDetail,
  },
  {
    path: pathUrl.ROLE.INDEX,
    title: "Role",
    component: role,
  },
  {
    path: pathUrl.ROLE.DETAIL,
    title: "Role detail",
    component: roleDetail,
  },
  {
    path: pathUrl.PERMISSION.INDEX,
    title: "Permission",
    component: permission,
  },
  {
    path: pathUrl.PERMISSION.DETAIL,
    title: "Permission detail",
    component: permissionDetail,
  },
  {
    path: pathUrl.ROLE_PERMISSION.INDEX,
    title: "role permission",
    component: rolePermission,
  },
  {
    path: pathUrl.MERCHANT.INDEX,
    title: "merchant",
    component: merchant,
  },
  {
    path: pathUrl.MERCHANT.DETAIL,
    title: "Merchant detail",
    component: merchantDetail,
  },
  {
    path: pathUrl.STORE.INDEX,
    title: "store",
    component: store,
  },
  {
    path: pathUrl.STORE.DETAIL,
    title: "Store detail",
    component: storeDetail,
  },
  {
    path: pathUrl.GROUP_DEVICE.INDEX,
    title: "group device",
    component: groupDevice,
  },
  {
    path: pathUrl.GROUP_DEVICE.DETAIL,
    title: "Group device detail",
    component: groupDeviceDetail,
  },
  {
    path: pathUrl.SERVICE.INDEX,
    title: "service",
    component: service,
  },
  {
    path: pathUrl.SERVICE.DETAIL,
    title: "Service detail",
    component: serviceDetail,
  },
  {
    path: pathUrl.SCHEDULE.INDEX,
    title: "schedule",
    component: schedule,
  },

  {
    path: pathUrl.SCHEDULE.DETAIL,
    title: "schedule detail",
    component: scheduleDetail,
  },
  {
    path: pathUrl.BOOKING_OBJECT.INDEX,
    title: "booking object",
    component: bookingObject,
  },
  {
    path: pathUrl.BOOKING_OBJECT.DETAIL,
    title: "booking object detail",
    component: bookingObjectDetail,
  },
  {
    path: pathUrl.RELAY_DEVICE.INDEX,
    title: "relay device",
    component: relayDevice,
  },
  {
    path: pathUrl.RELAY_DEVICE.DETAIL,
    title: "relay device detail",
    component: relayDeviceDetail,
  },
  {
    path: pathUrl.CONFIG.INDEX,
    title: "config",
    component: config,
  },
  {
    path: pathUrl.CONFIG.DETAIL,
    title: "config detail",
    component: configDetail,
  },
  {
    path: pathUrl.TRACKING_REQUEST.INDEX,
    title: "tracking request",
    component: trackingRequest,
  },
  {
    path: pathUrl.TRACKING_REQUEST.DETAIL,
    title: "tracking request detail",
    component: trackingRequestDetail,
  },

];





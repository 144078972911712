import { combineReducers } from "redux";
import { commonReducer } from "./common/common.reducers";
import { userReducer } from "./user/user.reducers";

const appReducer = combineReducers({
  commonReducer: commonReducer,
  userReducer: userReducer,
});
export type RootState = ReturnType<typeof appReducer>;
export type FGetState = () => RootState;
export default appReducer;
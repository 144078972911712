export const pathUrl = {
  HOME: "/",
  LOGIN: "/login",
  PAGE_500: "/page-500",
  PAGE_400: "/page-400",
  CHANGE_PASSWORD: "/change-password",
  MASTER: "/master",
  USER: {
    INDEX: "/user",
    DETAIL: "/user/detail",
  },
  PERMISSION: {
    INDEX: "/permission",
    DETAIL: "/permission/detail",
  },
  ROLE: {
    INDEX: "/role",
    DETAIL: "/role/detail",
  },
  ROLE_PERMISSION: {
    INDEX: "/role-permission",
  },
  MERCHANT: {
    INDEX: "/merchant",
    DETAIL: "/merchant/detail",
  },
  STORE: {
    INDEX: "/store",
    DETAIL: "/store/detail",
  },
  GROUP_DEVICE: {
    INDEX: "/group-device",
    DETAIL: "/group-device/detail",
  },
  SERVICE: {
    INDEX: "/service",
    DETAIL: "/service/detail",
  },
  SCHEDULE: {
    INDEX: "/schedule",
    DETAIL: "/schedule/detail",
  },
  BOOKING_OBJECT: {
    INDEX: "/booking-object",
    DETAIL: "/booking-object/detail",
  },
  RELAY_DEVICE: {
    INDEX: "/relay-device",
    DETAIL: "/relay-device/detail",
  },
  CONFIG: {
    INDEX: "/config",
    DETAIL: "/config/detail",
  },
  TRACKING_REQUEST: {
    INDEX: "/tracking-request",
    DETAIL: "/tracking-request/detail",
  },
  EMPTY: "",
};



export const commonType = {
  SPIN: {
    SHOW: "SPIN_SHOW",
    HIDE: "SPIN_HIDE",
  },
  DATA_APP_WALLET: {
    START: "START",
    END: "END",
  },
  SET_FORMAT_DATE_TIME: "SET_FORMAT_DATE_TIME"
};

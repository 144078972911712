export const permissions = {
  admin: {
    user: {
      admin_user: "admin_user",
      admin_user_add: "admin_user_add",
      admin_user_edit: "admin_user_edit",
      admin_user_deleted: "admin_user_deleted",
      admin_user_export: "admin_user_export",
      admin_user_import: "admin_user_import",
    },
    role: {
      admin_role: "admin_role",
      admin_role_add: "admin_role_add",
      admin_role_edit: "admin_role_edit",
      admin_role_deleted: "admin_role_deleted",
      admin_role_export: "admin_role_export",
      admin_role_import: "admin_role_import",
    },
    permission: {
      admin_permission: "admin_permission",
      admin_permission_add: "admin_permission_add",
      admin_permission_edit: "admin_permission_edit",
      admin_permission_deleted: "admin_permission_deleted",
      admin_permission_export: "admin_permission_export",
      admin_permission_import: "admin_permission_import",
    },
    role_permission: {
      admin_role_permission: "admin_role_permission",
      admin_role_permission_add: "admin_role_permission_add",
      admin_role_permission_edit: "admin_role_permission_edit",
      admin_role_permission_deleted: "admin_role_permission_deleted",
      admin_role_permission_export: "admin_role_permission_export",
      admin_role_permission_import: "admin_role_permission_import",
    },
  },
  control_center: {
    control_center: "control_center",
    control_center_add: "control_center_add",
    control_center_edit: "control_center_edit",
    control_center_deleted: "control_center_deleted",
    control_center_export: "control_center_export",
    control_center_import: "control_center_import",
  },
  relay_device: {
    relay_device: "relay_device",
    relay_device_add: "relay_device_add",
    relay_device_edit: "relay_device_edit",
    relay_device_deleted: "relay_device_deleted",
    relay_device_export: "relay_device_export",
    relay_device_import: "relay_device_import",
  },
  merchant: {
    merchant: "merchant",
    merchant_add: "merchant_add",
    merchant_edit: "merchant_edit",
    merchant_deleted: "merchant_deleted",
    merchant_export: "merchant_export",
    merchant_import: "merchant_import",
  },
  store: {
    store: "store",
    store_add: "store_add",
    store_edit: "store_edit",
    store_deleted: "store_deleted",
    store_export: "store_export",
    store_import: "store_import",
  },
  group_device: {
    group_device: "group_device",
    group_device_add: "group_device_add",
    group_device_edit: "group_device_edit",
    group_device_deleted: "group_device_deleted",
    group_device_export: "group_device_export",
    group_device_import: "group_device_import",
  },
  schedule: {
    schedule: "schedule",
    schedule_add: "schedule_add",
    schedule_edit: "schedule_edit",
    schedule_deleted: "schedule_deleted",
    schedule_export: "schedule_export",
    schedule_import: "schedule_import",
  },
  booking_object: {
    booking_object: "booking_object",
    booking_object_add: "booking_object_add",
    booking_object_edit: "booking_object_edit",
    booking_object_deleted: "booking_object_deleted",
    booking_object_export: "booking_object_export",
    booking_object_import: "booking_object_import",
  },
  service: {
    service: "service",
    service_add: "service_add",
    service_edit: "service_edit",
    service_deleted: "service_deleted",
    service_export: "service_export",
    service_import: "service_import",
  },
  config: {
    config: "config",
    config_add: "config_add",
    config_edit: "config_edit",
    config_deleted: "config_deleted",
    config_export: "config_export",
    config_import: "config_import",
  },
  tracking_request: {
    tracking_request: "tracking_request",
    tracking_request_add: "tracking_request_add",
    tracking_request_edit: "tracking_request_edit",
    tracking_request_deleted: "tracking_request_deleted",
    tracking_request_export: "tracking_request_export",
    tracking_request_import: "tracking_request_import",
  },
};



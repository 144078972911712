import { userType } from "./user.type";

const initialState = {
  userInfo: undefined,
  lstPermission: [],
};

export function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case userType.SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload
      };
    case userType.SET_PERMISSIONS:
      return {
        ...state,
        lstPermission: action.payload
      };
    default:
      return state;
  }
}

/* eslint-disable eqeqeq */
import { Avatar, Button, Col, Drawer, Flex, Form, Image, Menu, MenuProps, Row, Select } from "antd";
import React, { Component } from "react";
import { LocalizeContextProps, Translate, withLocalize } from "react-localize-redux";
import { LangEnum } from "../../constants/enums";
import { IMAGE } from "../../assets/images";
import { Header } from "antd/es/layout/layout";
import { LogoutOutlined, SyncOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { globalProps } from "../../data/props";
import { getNavigate } from "../../navigate-service.";
import { pathUrl } from "../../data/path-url";
import { lstHeaderFormatDateTime } from "../../constants/config";
import { isAllow, leftMenu } from "../../data/left-menu";
import { NavLink } from "react-router-dom";
import AppMenu from "../app-menu";

type IProps = LocalizeContextProps & {
  browserWeb: string,
  userInfo: any,
  lang: any,
  formatDatetime: any,
  onChangeLang: (newLang: string) => void;
  onChangeDatetime: (newDatetime: string) => void;
  getUserInfo: () => void;
};

interface IState {
  showProfile: boolean,
  collapsed: boolean,
};

class AppHeader extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showProfile: false,
      collapsed: false,
    }
  }

  onShowProfile(value: boolean) {
    this.setState({
      showProfile: value
    });

  }
  onLogout() {
    this.onShowProfile(false);
    localStorage.removeItem("acc_token");
    localStorage.removeItem("user_info");
    this.props.getUserInfo();
  }

  onChangePassword() {
    this.onShowProfile(false);
    const navigate = getNavigate();
    navigate(pathUrl.CHANGE_PASSWORD);
  }

  onChangeCollapsed(value: boolean) {
    this.setState({
      collapsed: value
    })
  }

  render() {
    let { collapsed, showProfile } = this.state;
    let {
      userInfo, browserWeb,
      lang, onChangeLang,
    } = this.props;

    return (
      <React.Fragment>
        <Header className="app-header">
          <Flex wrap gap="small" justify="space-between" align="center" className="flex-header">
            <div className="collapsed-logo">
              {
                userInfo && browserWeb == "MOBLIE" && (
                  <Button
                    type="text"
                    className="icon-collapsed"
                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    onClick={() => this.onChangeCollapsed(!collapsed)}
                  />
                )
              }
              {
                userInfo && browserWeb == "PC" && (
                  <div className="logo">
                    <Image loading='lazy' src={IMAGE.logo} preview={false} />
                  </div>
                )
              }
            </div>
            <Flex gap={30} align="center">
              {/* format ngày thánh */}
              {/* <div className="choose-format-date-time">
                <Select {...globalProps.selectSearch as any} allowClear value={formatDatetime}
                  onChange={(e) => {
                    onChangeDatetime(e);
                  }}>
                  {lstHeaderFormatDateTime.map((k, i) =>
                    <Option key={`format-date-time${i}`} value={k.value}>
                      {k.lang}
                    </Option>
                  )}
                </Select>
              </div> */}
              {/* Đổi ngôn ngữ */}
              {lang == LangEnum.VI && (
                <div className="lang-group">
                  <Image loading='lazy' onClick={() => onChangeLang(LangEnum.EN)} preview={false} src={IMAGE.logoVi} />
                  <div><strong>VN</strong></div>
                </div>
              )}
              {lang == LangEnum.EN && (
                <div className="lang-group">
                  <Image loading='lazy' onClick={() => onChangeLang(LangEnum.VI)} preview={false} src={IMAGE.logoEn} />
                  <div><strong>EN</strong></div>
                </div>
              )}
              {/*  Thông tin user */}
              {
                userInfo && (
                  <div className="user-info">
                    <Avatar onClick={() => this.onShowProfile(true)}>
                      {userInfo?.name}
                    </Avatar>
                  </div>
                )
              }
            </Flex>
          </Flex>
        </Header>
        <Drawer
          title={
            <div className="logo">
              <Image loading='lazy' src={IMAGE.logo} preview={false} />
            </div>
          }
          size="default"
          placement="left"
          width={200}
          closable={false}
          onClose={(_) => this.onChangeCollapsed(!collapsed)}
          open={collapsed}
          className="drawer-menu"
        >
          <AppMenu mode="inline" />
        </Drawer>
        {userInfo && (
          <Drawer
            title={<Translate id="lbl_profile" />}
            placement="right"
            closable={true}
            onClose={(_) => this.onShowProfile(false)}
            open={showProfile}
          >
            <Row {...globalProps.row24 as any} >
              <Col span={24}>
                <label> Hello {userInfo.name}</label>
              </Col>
              <Col span={24}>
                <Flex gap={20}>
                  <Button onClick={(_) => this.onLogout()} type="primary">
                    <span>
                      <Translate id="lbl_logout" />
                    </span>
                    <LogoutOutlined />
                  </Button>
                  <Button type="primary" onClick={(_) => this.onChangePassword()}>
                    <span>
                      <Translate id="lbl_change_password" />
                    </span>
                    <SyncOutlined />
                  </Button>
                </Flex>
              </Col>
            </Row>
          </Drawer>
        )}

      </React.Fragment>
    );
  }
}
export default withLocalize(AppHeader)
